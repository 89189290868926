import $ from 'jquery';

$(() => {
  // 各エリアのチェックボックスが選択された場合、全てのチェックをOff、
  // または全て手動でチェックされた場合は「全て」のチェックボックをOnにする
  function switchAreaAllCheckbox() {
    // 全てのチェックを外す
    $('#spot_area_id_all').prop('checked', false);
    // 全て手動でチェックされた場合、チェックボックスの「全て」にチェックをつける
    if ($('.spot_area_ids:checked').length === $('.spot_area_ids').length) {
      $('#spot_area_id_all').prop('checked', true);
    }
  }

  // 現在地取得成功時のみサーバに処理を送信する
  function successGetPosition(position) {
    const url = $('#near_current_location_btn').attr('href');
    $('#near_current_location_btn').attr(
      'href',
      `${url}&lat=${position.coords.latitude}&lng=${position.coords.longitude}`
    );
    window.location.href = `${url}&lat=${position.coords.latitude}&lng=${position.coords.longitude}`;
  }

  function failGetPosition(error) {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        alert('位置情報の取得が許可されませんでした');
        break;
      case error.POSITION_UNAVAILABLE:
        alert('電波状況などで位置情報が取得できませんでした');
        break;
      case error.TIMEOUT:
        alert('位置情報の取得にてタイムアウトが発生しました');
        break;
      case error.UNKNOWN_ERROR:
        alert('原因不明のエラーが発生しました');
        break;
    }
  }

  // エリア
  if ($('#spot_area_id_all').length) {
    // 全てが選択された場合、ジャンルを全てのチェックをonに設定する
    $('#spot_area_id_all').click(function f() {
      // PCの場合、地図に表示するチェックボックスと地図右側に表示するチェックボックスが存在しており、
      // 表示内容は同一のためチェックボックスのOn/Offを連動する必要がある。
      // その為以下でOn/Offの連動を制御している
      const isGenreAllChecked = $(this).prop('checked');
      // 検索に使用するチェックボックス
      $('input[name="q[areas_id_in][]"]').each(function _f() {
        $(this).prop('checked', isGenreAllChecked);
      });
      // 画面表示に使用するチェックボックス
      $('input[name="spot_area[ids][]"]').each(function _f() {
        $(this).prop('checked', isGenreAllChecked);
      });
    });

    // 検索に使用するチェックボックスのOn/Offを変更した場合
    $('input[name="q[areas_id_in][]"]').change(function f() {
      const isGenreAllChecked = $(this).prop('checked');
      $(`#spot_area_ids_${$(this).attr('id').slice(-1)}`).prop('checked', isGenreAllChecked);
      switchAreaAllCheckbox();
    });

    // 画面表示に使用するチェックボックスのOn/Offを変更した場合
    $('input[name="spot_area[ids][]"]').change(function f() {
      const isGenreAllChecked = $(this).prop('checked');
      $(`#q_areas_id_in_${$(this).attr('id').slice(-1)}`).prop('checked', isGenreAllChecked);
      switchAreaAllCheckbox();
    });
  }

  // カテゴリー
  if ($('#spot_category_id_all').length) {
    // 全てが選択された場合、ジャンルを全てのチェックをonに設定する
    $('#spot_category_id_all').click(function f() {
      const isGenreAllChecked = $(this).prop('checked');
      $('input[name="q[categories_id_in][]"]').each(function _f() {
        $(this).prop('checked', isGenreAllChecked);
      });
    });

    // カテゴリーのいずれかのチェックボックスをoffにした場合、全てをoffに設定する
    $('input[name="q[categories_id_in][]"]').change(function f() {
      $('#spot_category_id_all').prop('checked', false);
    });
  }

  // 現在地に近いボタン押下時、現在地を取得する
  if ($('#near_current_location_btn').length) {
    $('#near_current_location_btn').click(function f() {
      const options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      };

      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(successGetPosition, failGetPosition, options);
      } else {
        alert('ご使用中のブラウザは現在地検索に対応されておりません');
      }
      return false;
    });
  }
});
