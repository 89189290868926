import $ from 'jquery';

$(() => {
  if ($('.topic_content_item').length) {
    // 要素が5件以上ある場合は初期表示時非表示にする
    $.each($('.topic_content_item'), function (i, val) {
      if (i >= 5) {
        $(val).css('display', 'none');
      }
    });
  }

  // もっと読むボタン押下時の処理
  // 処理内容：
  // 1.もっと読むボタンを非表示
  // 2.非表示のコンテンツを表示する
  if ($('#topic_content_more_read').length) {
    $('#topic_content_more_read').click(function f() {
      $('#topic_content_more_read').hide();
      $.each($('.topic_content_item'), function (i, val) {
        if (i >= 5) {
          $(val).css('display', '');
        }
      });
    });
  }

  // 各エリアのチェックボックスが選択された場合、全てのチェックをOff、
  // または全て手動でチェックされた場合は「全て」のチェックボックをOnにする
  function switchAreaAllCheckbox() {
    // 全てのチェックを外す
    $('#topic_area_id_all').prop('checked', false);
    // 全て手動でチェックされた場合、チェックボックスの「全て」にチェックをつける
    if ($('.topic_area_ids:checked').length === $('.topic_area_ids').length) {
      $('#topic_area_id_all').prop('checked', true);
    }
  }

  // エリア
  if ($('#topic_area_id_all').length) {
    // 全てが選択された場合、ジャンルを全てのチェックをonに設定する
    $('#topic_area_id_all').click(function f() {
      // PCの場合、地図に表示するチェックボックスと地図右側に表示するチェックボックスが存在しており、
      // 表示内容は同一のためチェックボックスのOn/Offを連動する必要がある。
      // その為以下でOn/Offの連動を制御している
      const isGenreAllChecked = $(this).prop('checked');
      // 検索に使用するチェックボックス
      $('input[name="q[areas_id_in][]"]').each(function _f() {
        $(this).prop('checked', isGenreAllChecked);
      });
      // 画面表示に使用するチェックボックス
      $('input[name="topic_area[ids][]"]').each(function _f() {
        $(this).prop('checked', isGenreAllChecked);
      });
    });

    // カテゴリー
    if ($('#classification_id_all').length) {
      // 全てが選択された場合、ジャンルを全てのチェックをonに設定する
      $('#classification_id_all').click(function f() {
        const isGenreAllChecked = $(this).prop('checked');
        $('input[name="q[classifications_id_in][]"]').each(function _f() {
          $(this).prop('checked', isGenreAllChecked);
        });
      });

      // カテゴリーのいずれかのチェックボックスをoffにした場合、全てをoffに設定する
      $('input[name="q[classifications_id_in][]"]').change(() => {
        $('#classification_id_all').prop('checked', false);
      });
    }

    // カテゴリー
    if ($('#season_id_all').length) {
      // 全てが選択された場合、ジャンルを全てのチェックをonに設定する
      $('#season_id_all').click(function f() {
        const isGenreAllChecked = $(this).prop('checked');
        $('input[name="q[seasons_id_in][]"]').each(function _f() {
          $(this).prop('checked', isGenreAllChecked);
        });
      });

      // カテゴリーのいずれかのチェックボックスをoffにした場合、全てをoffに設定する
      $('input[name="q[seasons_id_in][]"]').change(() => {
        $('#season_id_all').prop('checked', false);
      });
    }

    // 検索に使用するチェックボックスのOn/Offを変更した場合
    $('input[name="q[areas_id_in][]"]').change(function f() {
      const isGenreAllChecked = $(this).prop('checked');
      $(`#topic_area_ids_${$(this).attr('id').slice(-1)}`).prop('checked', isGenreAllChecked);
      switchAreaAllCheckbox();
    });

    // 画面表示に使用するチェックボックスのOn/Offを変更した場合
    $('input[name="topic_area[ids][]"]').change(function f() {
      const isGenreAllChecked = $(this).prop('checked');
      $(`#q_areas_id_in_${$(this).attr('id').slice(-1)}`).prop('checked', isGenreAllChecked);
      switchAreaAllCheckbox();
    });
  }
});
