import $ from 'jquery';

$(() => {
  // 各エリアのチェックボックスが選択された場合、全てのチェックをOff、
  // または全て手動でチェックされた場合は「全て」のチェックボックをOnにする
  function switchAreaAllCheckboxForEvent() {
    // 全てのチェックを外す
    $('#event_area_id_all').prop('checked', false);
    // 全て手動でチェックされた場合、チェックボックスの「全て」にチェックをつける
    if ($('.event_area_ids:checked').length === $('.event_area_ids').length) {
      $('#event_area_id_all').prop('checked', true);
    }
  }

  // ジャンル
  if ($('#event_area_id_all').length) {
    // 全てが選択された場合、ジャンルを全てのチェックをonに設定する
    $('#event_area_id_all').click(function f() {
      const isGenreAllChecked = $(this).prop('checked');
      $('input[name="q[areas_id_in][]"]').each(function _f() {
        $(this).prop('checked', isGenreAllChecked);
      });
    });

    // ジャンルのチェックボックスをoffにした場合、全てをoffに設定する
    $('input[name="q[areas_id_in][]"]').change(function f() {
      $('#event_area_id_all').prop('checked', false);
      // 手動でエリアチェックボックスを全てチェックされた場合、「全て」チェックボックスをOnにする
      if ($('.event_area_ids:checked').length === $('.event_area_ids').length) {
        $('#event_area_id_all').prop('checked', true);
      }
    });
  }

  // エリア
  if ($('#event_area_id_all').length) {
    // 全てが選択された場合、ジャンルを全てのチェックをonに設定する
    $('#event_area_id_all').click(function f() {
      // PCの場合、地図に表示するチェックボックスと地図右側に表示するチェックボックスが存在しており、
      // 表示内容は同一のためチェックボックスのOn/Offを連動する必要がある。
      // その為以下でOn/Offの連動を制御している
      const isGenreAllChecked = $(this).prop('checked');
      // 検索に使用するチェックボックス
      $('input[name="q[areas_id_in][]"]').each(function _f() {
        $(this).prop('checked', isGenreAllChecked);
      });
      // 画面表示に使用するチェックボックス
      $('input[name="event_area[ids][]"]').each(function _f() {
        $(this).prop('checked', isGenreAllChecked);
      });
    });

    // 検索に使用するチェックボックスのOn/Offを変更した場合
    $('input[name="q[areas_id_in][]"]').change(function f() {
      const isGenreAllChecked = $(this).prop('checked');
      $(`#event_area_ids_${$(this).attr('id').slice(-1)}`).prop('checked', isGenreAllChecked);
      switchAreaAllCheckboxForEvent();
    });

    // 画面表示に使用するチェックボックスのOn/Offを変更した場合
    $('input[name="event_area[ids][]"]').change(function f() {
      const isGenreAllChecked = $(this).prop('checked');
      $(`#q_areas_id_in_${$(this).attr('id').slice(-1)}`).prop('checked', isGenreAllChecked);
      switchAreaAllCheckboxForEvent();
    });
  }
});
