import $ from 'jquery';

$(() => {
  // ジャンル
  if ($('#genre_id_all').length) {
    // 全てが選択された場合、ジャンルを全てのチェックをonに設定する
    $('#genre_id_all').click(function f() {
      const isGenreAllChecked = $(this).prop("checked");
      $('input[name="q[genres_id_in][]"]').each(function _f() {
        $(this).prop("checked", isGenreAllChecked);
      })
    });

    // ジャンルのチェックボックスをoffにした場合、全てをoffに設定する
    $('input[name="q[genres_id_in][]"]').change(function f() {
      if ($(this).attr('id') !== "genre_id_all") {
        $('#genre_id_all').prop("checked", false);
      }
    })
  }

  // 季節
  if ($('#season_id_all').length) {
    // 全てが選択された場合、ジャンルを全てのチェックをonに設定する
    $('#season_id_all').click(function f() {
      const isGenreAllChecked = $(this).prop("checked");
      $('input[name="q[seasons_id_in][]"]').each(function _f() {
        $(this).prop("checked", isGenreAllChecked);
      })
    });

    // ジャンルのチェックボックスをoffにした場合、全てをoffに設定する
    $('input[name="q[seasons_id_in][]"]').change(function f() {
      if ($(this).attr('id') !== "season_id_all") {
        $('#season_id_all').prop("checked", false);
      }
    })
  }

  // まとめてダウンロードチェックボックスを押下した場合
  // checked = true の場合、PhotoDlSelectionインスタンスを作成
  // checked = falseの場合、PhotoDlSelectionインスタンスを破棄
  if ($('input[name="dl_selected"]').length) {
    $('input[name="dl_selected"]').on('change', function f() {
      const photoId = $(this).attr('id').replace("dl_selected_", "");
      const $checkbox = $(this)
      // 新規登録
      if ($(this).prop("checked")) {
        $.ajax({
            url: `/photo_dl_selections`,
            beforeSend(xhr) {
              xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))
            },
            type:'POST',
            dataType: 'json',
            data: { photo_id: photoId },
            timeout: 3000,
        }).fail(function fail() {
          // エラーの場合は、チェックを外す
          $checkbox.prop("checked", false);
        });
      // 削除
      } else {
        $.ajax({
            url: `/photo_dl_selections/${photoId}`,
            beforeSend(xhr) {
              xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))
            },
            type:'DELETE',
            dataType: 'json',
            timeout: 3000,
          }).fail(function fail() {
            // エラーの場合、チェックを残す
            $checkbox.prop("checked", true);
        });
      }
    });
  }

  // 入力チェック
  // 戻り値についてtrue: 未入力なし/email妥当性OK false: 未入力あり/emailが不正
  function isActivationBulkDownload() {
    // 氏名：必須チェック
    if ($('#person_id').val().trim() === "") {
      return false;
    }

    // email：必須チェック
    if ($('#email_id').val().trim() === "") {
      return false;
    }

    const reg = /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/;
    if (!reg.test($('#email_id').val())) {
      return false;
    }

    // 利用目的：必須チェック
    if ($('#purpose_id').val().trim() === "") {
      return false;
    }
    // 利用規約チェックボックス
    // On:  一括ダウンロードボタンを活性化
    // Off: 一括ダウンロードボタンを非活性
    return $('#photo_terms_of_use').prop("checked")
  }

  function setBulkDownloadDisabled() {
    $('#bulk_download').prop('disabled', !isActivationBulkDownload())
  }

  // 一括ダウンロードする
  if ($('#bulk_download').length) {
    // 氏名が変更された場合
    $('#person_id').change(function f() {
      setBulkDownloadDisabled();
    });
    // emailが変更された場合
    $('#email_id').change(function f() {
      setBulkDownloadDisabled();
    });
    // 利用目的が変更された場合
    $('#purpose_id').change(function f() {
      setBulkDownloadDisabled();
    });
    // 利用規約のチェックボックスを変更された場合
    $('#photo_terms_of_use').change(function f() {
      setBulkDownloadDisabled();
    });
    // 初期表示設定
    setBulkDownloadDisabled();
  }
});
