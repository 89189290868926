/**
 * 紅葉・さくら・積雪ページ
 *
 * app/views/autumn_leaves_reports/index.html.erb
 * app/views/cherry_blossoms_reports/index.html.erb
 * app/views/snow_reports/index.html.erb
 *
 * で使用されています。
 */
import Swiper from 'swiper/bundle';

document.addEventListener('DOMContentLoaded', () => {
  if (document.querySelector('.season-swiper-container') !== null) {
    // 紅葉・さくら・積雪共用のSwiperスライダーを初期化
    // eslint-disable-next-line no-unused-vars
    const spotsShowSwiper = new Swiper('.season-swiper-container .swiper-container', {
      slidesPerView: 1,
      spaceBetween: 30,
      centeredSlides: true,
      loop: true,
      speed: 600,
      navigation: {
        nextEl: '.season-swiper-container .swiper-btn--type_next',
        prevEl: '.season-swiper-container .swiper-btn--type_prev',
      },
      breakpoints: {
        1200: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
        992: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        640: {
          slidesPerView: 2,
          spaceBetween: 10,
        },
        420: {
          slidesPerView: 1,
          spaceBetween: 30,
        },
      },
    });

    // 地図上の番号の要素
    const seasonNumbers = document.querySelectorAll('.season__position');

    // 地図横の地名一覧内の各要素
    const seasonSpotInfo = document.querySelectorAll('.season__table-spot-info');

    // スライダー全体の要素
    const target = document.querySelector('.season-slide');

    // スライダーのページネーション要素
    const nextButton = document.querySelector('.season-slide .swiper-btn--type_next');
    const prevButton = document.querySelector('.season-slide .swiper-btn--type_prev');

    /**
     * 指定された要素までスクロールする関数
     * @param {HTMLElement} element - スクロール対象の要素
     * @param {number} offset - スクロール位置のオフセット（省略可、デフォルト値は0）
     */
    const scrollToElement = (element, offset = 0) => {
      const rect = element.getBoundingClientRect();
      const top = rect.top + window.pageYOffset - offset;
      window.scrollTo({ top, behavior: 'smooth' });
    };

    /**
     * Swiperスライダーを指定されたスライドアイテムを中央にスクロールする関数
     * @param {number} index - スクロール対象のスライドのインデックス番号
     */
    const scrollToSlide = (index) => {
      spotsShowSwiper.slideToLoop(index, 300, false);
      const headerHeight = document.querySelector('header').offsetHeight;
      scrollToElement(target, headerHeight);
    };

    /**
     * スライダー内のアクティブなスライドから "season-slide__active" クラスを削除する関数
     */
    const removeActiveClass = () => {
      const allSwiperElement = document.querySelectorAll('[data-swiper-slide-index]');
      allSwiperElement.forEach((element) => {
        if (element.classList.contains('season-slide__active')) {
          element.classList.remove('season-slide__active');
        }
      });
    };

    if (nextButton && prevButton) {
      // 「次へ」ボタンがクリックされた時の処理
      nextButton.addEventListener('click', () => {
        // スライダー内のアクティブなスライドから "season-slide__active" クラスを削除する
        removeActiveClass();
      });

      // 「前へ」ボタンがクリックされた時の処理
      prevButton.addEventListener('click', () => {
        // スライダー内のアクティブなスライドから "season-slide__active" クラスを削除する
        removeActiveClass();
      });
    }

    /**
     * クリックイベントの共通処理を実行する関数
     * @param {HTMLElement} node - クリックされた要素
     */
    const handleClick = (node) => {
      // クリックされた番号に対応するスライドのインデックス番号を計算する
      const key = +node.getAttribute('data-value') - 1;

      const allSwiperElement = document.querySelectorAll('[data-swiper-slide-index]');
      allSwiperElement.forEach((element) => {
        if (element.classList.contains('season-slide__active')) {
          // スライド内のアクティブなスライドから "season-slide__active" クラスを削除する
          element.classList.remove('season-slide__active');
        }
        if (element.getAttribute('data-swiper-slide-index') === String(key)) {
          // クリックされた番号に対応するスライドに "season-slide__active" クラスを追加する
          element.classList.add('season-slide__active');
        }
      });

      // クリックされた番号に対応するスライドアイテムを中央にスクロールする
      scrollToSlide(key);
    };

    // seasonNumbersの各要素（地図上の番号）に対してクリックイベントリスナーを設定
    seasonNumbers.forEach((node) => {
      node.addEventListener('click', () => {
        handleClick(node);
      });
    });

    // seasonSpotInfoの各要素（地名一覧の地名）に対してクリックイベントリスナーを設定
    seasonSpotInfo.forEach((node) => {
      node.addEventListener('click', () => {
        handleClick(node);
      });
    });
  }
});
