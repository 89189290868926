import $ from 'jquery';

$(() => {
  if ($('#top_latest_event_tab').length) {
    // イベントタブを選択された場合、もっと見るのパスをイベント一覧に切り替える
    $('#top_latest_event_tab').click(() => {
      $('#top_latest_event_see_more').toggleClass('d-none');
      $('#top_latest_article_see_more').toggleClass('d-none');
    });
  }

  if ($('#top_latest_article_tab').length) {
    // ニュースタブを選択された場合、もっと見るのパスをニュース一覧に切り替える
    $('#top_latest_article_tab').click(() => {
      $('#top_latest_event_see_more').toggleClass('d-none');
      $('#top_latest_article_see_more').toggleClass('d-none');
    });
  }

  if ($('#recommend_spot').length) {
    $.ajax({
      url: `/top/ai_recommend`,
      beforeSend(xhr) {
        xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
      },
      type: 'GET',
      timeout: 5000,
    }).done(() => {
      $('#delete_images').remove();
    });
  }
});

// videoタグを遅延読み込み
document.addEventListener('DOMContentLoaded', () => {
  const lazyVideos = [].slice.call(document.querySelectorAll('video.js-lazy'));

  if ('IntersectionObserver' in window) {
    const lazyVideoObserver = new IntersectionObserver((entries) => {
      entries.forEach((video) => {
        if (video.isIntersecting) {
          // eslint-disable-next-line guard-for-in,no-restricted-syntax
          for (const source in video.target.children) {
            const videoSource = video.target.children[source];
            if (typeof videoSource.tagName === 'string' && videoSource.tagName === 'SOURCE') {
              videoSource.src = videoSource.dataset.src;
            }
          }

          video.target.load();
          video.target.classList.remove('is-lazy');
          lazyVideoObserver.unobserve(video.target);
        }
      });
    });

    lazyVideos.forEach((lazyVideo) => {
      lazyVideoObserver.observe(lazyVideo);
    });
  }
});

// facebookウィジェット遅延読み込み
const fbHTML =
  '<div id="fb-root"></div><div class="fb-page" data-href="https://www.facebook.com/akitavision/" data-tabs="timeline" data-width="400" data-height="600" data-small-header="false" data-adapt-container-width="false" data-hide-cover="false" data-show-facepile="true"><blockquote cite="https://www.facebook.com/akitavision/" class="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/akitavision/">あきたびじょん</a></blockquote></div>';
const fbJs = document.createElement('script');
fbJs.src = 'https://connect.facebook.net/ja_JP/sdk.js#xfbml=1&version=v14.0';
fbJs.crossorigin = 'anonymous';
const fb = document.querySelector('[data-js-role="facebook-widget-wrap"]');
let loaded = false;
if (fb !== null) {
  const loadTarget = fb;
  const loadOption = {
    root: null,
    rootMargin: '0px',
    threshold: 0,
  };
  const targets = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        if (!loaded) {
          fb.insertAdjacentHTML('beforebegin', fbHTML);
          fb.appendChild(fbJs);
          loaded = true;
        }
      }
    });
  };
  const loadObserver = new IntersectionObserver(targets, loadOption);
  loadObserver.observe(loadTarget);
}
