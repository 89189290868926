import $ from 'jquery';

$(() => {
  if ($('#tag_id_all').length) {
    // 全てが選択された場合、カテゴリーを全てのチェックをonに設定する
    $('#tag_id_all').click(function f() {
      const isTagAllChecked = $(this).prop("checked");
      $('input[type="checkbox"]').each(function _f() {
        $(this).prop("checked", isTagAllChecked);
      })
    });

    // カテゴリーのチェックボックスをoffにした場合、全てをoffに設定する
    $('input[type="checkbox"]').change(function f() {
      if ($(this).attr('id') !== "tag_id_all") {
        $('#tag_id_all').prop("checked", false);
      }
    })
  }
});
